import type { CreativeStage } from '@prisma/client';
import type * as icons from '~/assets/icons';
import type { AccessCode } from '~/types/accessCodes';

const creativeStages: {
  [key in CreativeStage]: {
    name: string;
    route: string;
    icon: keyof typeof icons;
    value: CreativeStage;
    kanbanColor: string;
  };
} = {
  PREPRODUCTION: {
    name: 'Pre-production',
    route: 'pre-production',
    icon: 'ThreeDSelectPoint',
    value: 'PREPRODUCTION',
    kanbanColor: 'bg-ht-kanban-row-fill-1',
  },
  PRODUCTION: {
    name: 'Production',
    route: 'production',
    icon: 'VideoCamera',
    value: 'PRODUCTION',
    kanbanColor: 'bg-ht-kanban-row-fill-2',
  },
  PUBLISHING: {
    name: 'Publishing',
    route: 'publishing',
    icon: 'ThreeDSelectFace',
    value: 'PUBLISHING',
    kanbanColor: 'bg-ht-kanban-row-fill-3',
  },
};

const creativeStageRoutes: {
  [stage in CreativeStage]: {
    route: string;
    label: string;
    accessCode: AccessCode;
  }[];
} = {
  PREPRODUCTION: [
    {
      route: 'scriptpad',
      label: 'Script Pad',
      accessCode: 'CL_CR_SCRIPT',
    },
    {
      route: 'talents',
      label: 'Talents',
      accessCode: 'CL_CR_TALENTS',
    },
    {
      route: 'outlets',
      label: 'Outlets',
      accessCode: 'CL_CR_OUTLETS',
    },
  ],
  PRODUCTION: [
    {
      route: 'preview',
      label: 'Preview',
      accessCode: 'CL_CR_PRODUCTION',
    },
  ],
  PUBLISHING: [
    {
      route: 'publish',
      label: 'Publish',
      accessCode: 'CL_CR_PUBLISHING',
    },
    {
      route: 'analytics',
      label: 'Analytics',
      accessCode: 'CL_CR_PUBLISHING',
    },
  ],
};

const creativeStageList: CreativeStage[] = [
  'PREPRODUCTION',
  'PRODUCTION',
  'PUBLISHING',
];

export { creativeStages, creativeStageList, creativeStageRoutes };
